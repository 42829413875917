import React, { useState } from 'react';
import { Desktop, Tablet } from '../../constants/responsive';
import { css } from '@emotion/core';
import FormBand from '../../svgs/banner-band-flipped.svg';
import { FormattedHTMLMessage, useIntl } from 'gatsby-plugin-intl';
import Button from '../Button';
import Container from '../Container';
import { useAppContext } from '../../context/AppContext';
import Axios from 'axios';
import Toast from '../Toast';

const errorMessages = {
  en: {
    missingName: 'Please enter your name',
    missingCompany: 'Please enter your company',
    missingEmail: 'Please enter your email',
    invalidEmail: 'Invalid email',
    missingPhone: 'Please enter your phone number',
    invalidPhone: 'Invalid phone number',
  },
  'zh-cn': {
    missingName: '请输入姓名',
    missingCompany: '请输入公司',
    missingEmail: '请输入电邮',
    invalidEmail: '电邮格式错误，请重新输入',
    missingPhone: '请输入电话',
    invalidPhone: '电话格式错误，请重新输入',
  },
  'zh-hk': {
    missingName: '請輸入姓名',
    missingCompany: '請輸入公司',
    missingEmail: '請輸入電郵',
    invalidEmail: '電郵格式錯誤，請重新輸入',
    missingPhone: '請輸入電話',
    invalidPhone: '電話格式錯誤，請重新輸入',
  },
};

const Input = ({
  type = 'text',
  placeholder,
  onChange = () => {},
  errorMessage = '',
  maxLength = 30,
}) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <input
        css={css`
          width: 100%;
          border-radius: 9px;
          padding: 0px 30px;
          background: #e8e8e8;
          font-weight: bold;
          color: #333;
          font-size: 14px;
          margin-bottom: 15px;
          &::placeholder {
            color: #969696;
            font-size: 14px;
            font-weight: 400;
          }
          height: 40px;
          ${Tablet} {
            height: 60px;
            font-size: 18px;
            &::placeholder {
              font-size: 18px;
            }
          }
          ${Desktop} {
            height: 60px;
          }
        `}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
      />
      <div
        css={css`
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #eb4335;
          margin-left: 30px;
          margin-bottom: 8px;
          margin-top: -7px;
          display: ${errorMessage ? 'block' : 'none'};
          ${Tablet} {
            font-size: 16px;
            margin-bottom: 12px;
          }
        `}
      >
        {errorMessage}
      </div>
    </div>
  );
};
const Select = ({
  options = [],
  placeholder,
  onChange = () => {},
  errorMessage = '',
  hasValue = false,
}) => {
  const intl = useIntl();
  const [showOptions, setShowOptions] = useState(false);
  const [value, setValue] = useState(
    intl.formatMessage({
      id: 'institutions.form.company-type',
    })
  );
  return (
    <div
      css={css`
        position: relative;

        }
        ${Tablet} {
         
        }
        ${Desktop} {
          
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          border-radius: 9px;
          padding: 0px 30px;
          background: #e8e8e8;
          font-weight: ${hasValue ? 'bold' : 400};
          font-size: 14px;
          color: ${hasValue ? '#333' : '#969696'};
          height: 40px;
          padding-top: 10px;
          position: relative;
          margin-bottom: 15px;
          &:after {
            content: '';
            width: 14px;
            height: 8px;
            background: url(/footer/arrow.png) no-repeat center;
            background-size: contain;
            //通过定位将图标放在合适的位置
            position: absolute;
            right: 20px;
            top: 40%;
            pointer-events: none;
          }
          ${Tablet} {
            padding-top: 19px;
            height: 60px;
            font-weight: ${hasValue ? 'bold' : 400};
            font-size: 18px;
            &:after {
              width: 21px;
              height: 12px;
            }
          }
          ${Desktop} {
            height: 60px;
          }
        `}
        placeholder={placeholder}
        onClick={() => {
          const value = !showOptions;
          setShowOptions(value);
        }}
      >
        <span
          css={css`
            ${Tablet} {
            }
          `}
        >
          {value}
        </span>
        <div
          css={css`
            display: ${showOptions ? 'block' : 'none'};
            background: #fbfbfb;
            box-shadow: 0px 3px 60px 1px rgba(233, 233, 233, 0.8);
            border-radius: 9px;
            z-index: 100;
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            transform: translateY(-100%);
          `}
        >
          {options.map((item, index) => {
            return (
              <div
                css={css`
                  height: 32px;
                  line-height: 32px;
                  cursor: pointer;
                  padding-left: 27px;
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 22px;
                  color: #a9a9a9;
                  &:hover {
                    background: #e4e4e4;
                    color: #676767;
                  }
                `}
                onClick={() => {
                  setValue(item.label);
                  onChange(item.label);
                }}
                key={item.label}
                value={item.label}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
      <div
        css={css`
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #eb4335;
          margin-left: 30px;
          margin-bottom: 8px;
          margin-top: -7px;
          display: ${errorMessage ? 'block' : 'none'};
          ${Tablet} {
            font-size: 16px;
            margin-bottom: 12px;
          }
        `}
      >
        {errorMessage}
      </div>
    </div>
  );
};
const Textarea = ({
  options = [],
  placeholder,
  onChange = () => {},
  errorMessage = '',
  maxLength = 300,
}) => {
  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <textarea
        css={css`
          width: 100%;
          height: 360px;
          border-radius: 9px;
          padding: 30px 30px;
          background: #e8e8e8;
          font-weight: bold;
          color: #333;
          font-size: 14px;
          // margin-bottom: 12px;
          &::placeholder {
            color: #969696;
            font-size: 14px;
            font-weight: 400;
            line-height: 2;
          }
          ${Tablet} {
            font-size: 18px;
            &::placeholder {
              font-size: 18px;
              font-weight: 400;
              line-height: 1.33;
            }
          }
          ${Desktop} {
          }
        `}
        name='textarea'
        placeholder={placeholder}
        onChange={onChange}
        maxLength={maxLength}
      ></textarea>
      <div
        css={css`
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #eb4335;
          margin-left: 30px;
          margin-bottom: 8px;
          margin-top: 8px;
          display: ${errorMessage ? 'block' : 'none'};
          ${Tablet} {
            font-size: 16px;
            margin-bottom: 12px;
          }
        `}
      >
        {errorMessage}
      </div>
    </div>
  );
};
const ContactForm = () => {
  const intl = useIntl();
  const selectOptions = [
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Technology' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Investment' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Product' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Relationship' }),
    },
    { value: 1, label: intl.formatMessage({ id: 'institutions.form.Sales' }) },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Strategy' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Marketing' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Digital' }),
    },
    {
      value: 1,
      label: intl.formatMessage({ id: 'institutions.form.Operations' }),
    },
    { value: 1, label: intl.formatMessage({ id: 'institutions.form.Other' }) },
  ];
  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [companyType, setCompanyType] = useState('');
  const [email, setEmail] = useState('');
  const [remark, setRemark] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [companyError, setCompanyError] = useState('');
  const [companyTypeError, setCompanyTypeError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [remarkError, setRemarkError] = useState('');
  const [success, setSuccess] = useState(false);
  const inputTips = intl.formatMessage({ id: 'institutions.form.required' });
  const selectTips = intl.formatMessage({ id: 'institutions.form.select' });

  const resetError = () => {
    // debugger;
    setFirstNameError('');
    setLastNameError('');
    setCompanyError('');
    setCompanyTypeError('');
    setCompanyTypeError('');
    setEmailError('');
    setRemarkError('');
  };

  const { institutionsFormRef, setErrorCode, apiUrl } = useAppContext();
  const errorMessageMap = {
    en: 'EN',
    'zh-cn': 'SC',
    'zh-hk': 'TC',
  };
  return (
    <div
      ref={institutionsFormRef}
      css={css`
        width: 100%;
        // margin-top: 80px;
        ${Desktop} {
          // margin-top: 160px;
        }
      `}
    >
      {/* <div
        css={css`
          height: 25px;
          width: 100%;
          min-width: 320px;
          * {
            fill: #003e86;
          }
          ${Tablet} {
            height: 40px;
          }
          ${Desktop} {
            height: 70px;
          }
        `}
      >
        <FormBand width='100%' height='100%' />
      </div> */}
      <div
        css={css`
          background: url('/footer/contact-bg.png') no-repeat center;
          background-size: 100% 100%;
          display: flex;
          justify-content: center;
          // height: 713px;
          ${Tablet} {
            // height: 743px;
          }
          ${Desktop} {
          }
        `}
      >
        <Container
          css={css`
            padding-top: 71px;
            padding-bottom: 56px;
          `}
        >
          <span
            css={css`
              color: #333333;
              text-align: center;
              font-weight: bold;
              font-size: 26px;
              line-height: 1.73;
              margin-top: 75.1px;
              ${Tablet} {
                font-size: 40px;
                line-height: 1.13;
                margin-top: 103.1px;
              }
              ${Desktop} {
                font-size: 40px;
                line-height: 1.13;
              }
            `}
          >
            <FormattedHTMLMessage id='institutions.form.title' />
          </span>
          <div
            css={css`
              color: #333333;
              font-weight: 400;
              font-size: 14px;
              line-height: 1.14;
              margin-top: 4.1px;
              margin-bottom: 22.8px;
              ${Tablet} {
                font-size: 18px;
                line-height: 1.33;
                margin-top: 7px;
                margin-bottom: 33px;
              }
              ${Desktop} {
                font-size: 18px;
                line-height: 1.33;
                margin-top: 7px;
                margin-bottom: 33px;
              }
            `}
          >
            <FormattedHTMLMessage id='institutions.form.before-email' />
            <br
              css={css`
                display: block;
                white-space: pre;
                ${Tablet} {
                  display: none;
                }
              `}
            />
            {`  wealthtech@aqumon.com`}
          </div>
          <div
            css={css`
              width: 100%;
              display: flex;
              flex-wrap: wrap;
            `}
          >
            <div
              css={css`
                // padding: 0px 20px;
                width: 100%;
                ${Tablet} {
                  width: 45%;
                  margin-right: 10%;
                }
                ${Desktop} {
                  width: 45%;
                  margin-right: 10%;
                }
              `}
            >
              <Input
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                errorMessage={firstNameError}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.first-name',
                })}
              />
              <Input
                onChange={(e) => {
                  setLastName(e.target.value);
                }}
                errorMessage={lastNameError}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.last-name',
                })}
              />
              <Input
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                errorMessage={companyError}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.company',
                })}
              />

              <Select
                onChange={(value) => {
                  setCompanyType(value);
                }}
                options={selectOptions}
                hasValue={companyType}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.company-type',
                })}
                errorMessage={companyTypeError}
              ></Select>
              <Input
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                maxLength={50}
                errorMessage={emailError}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.email-addr',
                })}
              />
            </div>
            <div
              css={css`
                width: 100%;
                ${Tablet} {
                  width: 45%;
                }
                ${Desktop} {
                  width: 45%;
                }
              `}
            >
              <Textarea
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
                errorMessage={remarkError}
                placeholder={intl.formatMessage({
                  id: 'institutions.form.enquiry',
                })}
              ></Textarea>
            </div>
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
              margin-top: 38px;
            `}
          >
            <Button
              css={css`
                width: 178px;
                height: 47px;
                border-radius: 41px;
                background-color: #ee2f79;

                font-size: 18px;
                font-weight: bold;
                line-height: normal;
              `}
              disabled={loading}
              onClick={async () => {
                setLoading(true);
                resetError();
                let error = false;
                if (!firstName || firstName.trim() === '') {
                  error = true;
                  setFirstNameError(inputTips);
                }
                if (!lastName || lastName.trim() === '') {
                  error = true;
                  setLastNameError(inputTips);
                }
                if (!company) {
                  error = true;
                  setCompanyError(inputTips);
                }
                if (!companyType) {
                  error = true;
                  setCompanyTypeError(selectTips);
                }
                if (!email) {
                  error = true;
                  setEmailError(inputTips);
                } else if (
                  !/^[A-Z0-9._%+-]{1,30}@[A-Z0-9.-]{1,50}\.[A-Z]{2,10}$/i.test(
                    email
                  )
                ) {
                  error = true;
                  setEmailError(errorMessages[intl.locale]['invalidEmail']);
                }
                if (!remark) {
                  error = true;
                  setRemarkError(inputTips);
                }
                if (!error) {
                  const contentObj = {
                    firstName,
                    lastName,
                    companyType,
                    company,
                    remark,
                    email,
                  };
                  const content = JSON.stringify(contentObj);
                  const response = await Axios.post(
                    `${apiUrl}/api/v4/users/orgs/contact`,
                    {
                      type: 3,
                      name: firstName + lastName,
                      company,
                      email,
                      content,
                    }
                  );
                  if (response.data.status.ecode) {
                    // setErrorCode(response.data.status.ecode);
                    // 原来是显示错误代码，现调整为显示错误三语信息
                    setErrorCode(
                      response.data.status[
                        `message${errorMessageMap[intl.locale]}`
                      ]
                    );
                  } else {
                    const tips = intl.formatMessage({
                      id: 'institutions.form.success',
                    });
                    Toast(tips);
                    setTimeout(() => {
                      window.location.reload();
                    }, 3000);
                  }
                }
                setLoading(false);
              }}
            >
              {loading ? (
                {
                  en: 'Submitting...',
                  'zh-cn': '提交中...',
                  'zh-hk': '提交中...',
                }[intl.locale]
              ) : (
                <FormattedHTMLMessage id='institutions.form.submit' />
              )}
            </Button>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default ContactForm;
